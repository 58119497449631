import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { collection, doc, orderBy, query } from "firebase/firestore";
import { firestore } from "lib/firebase";
import React from "react";

import "./style.scss";
import ThemeItem from "../item";
import { Grid } from "@mui/material";

interface IThemes {
  id: string;
  title: string;
}

const ThemesList = () => {
  const ref = query(collection(firestore, "Themes"), orderBy("order"));

  const themesQuery = useFirestoreQuery(["Themes"], ref, { subscribe: true });

  if (themesQuery.isLoading) {
    return <div>Loading...</div>;
  }

  const snapshot = themesQuery.data;

  return (
    <div className="theme-list-container">
      {snapshot.docs.map((docSnapshot) => {
        const data = docSnapshot.data();

        return (
          <ThemeItem
            key={docSnapshot.id}
            data={{ id: docSnapshot.id, ...data }}
          />
        );
      })}
    </div>
  );
};

export default ThemesList;
