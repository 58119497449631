import React from "react";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { collection, query } from "firebase/firestore";
import { firestore } from "lib/firebase";

interface IRelationDataProps {
  value: string;
  relationalCollection: string;
  relationalKey: string;
}

const ViewRelationalData = (props: IRelationDataProps) => {
  const { value, relationalCollection, relationalKey } = props;

  const ref = query(collection(firestore, relationalCollection));

  const { data, isLoading } = useFirestoreQuery(relationalCollection, ref);

  if (!value) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  const relationalValue = data.docs.find((doc) => doc.id === value);

  if (!relationalValue) {
    return null;
  }

  return <>{relationalValue.data()[relationalKey]}</>;
};

export default ViewRelationalData;
