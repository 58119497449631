import {
  AppBar,
  Dialog,
  Toolbar,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import QuestionsList from "../list";
import QuestionAddDialog from "../add";
import PlaylistDeleteDialog from "components/playlists/delete";

interface IQuestionsDialogProps {
  playlistId: string;
  themeId: string;
  close: () => void;
}

const QuestionsDialog = (props: IQuestionsDialogProps) => {
  const { playlistId, themeId, close } = props;
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
  const [deletePlaylistDialogOpen, setDeletePlaylistDialogOpen] =
    useState<boolean>(false);

  if (!playlistId || !themeId) {
    return null;
  }

  return (
    <Dialog open={!!playlistId} onClose={close} fullScreen>
      {/* <DialogContent> */}
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {playlistId}
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => setAddDialogOpen(true)}
          >
            Ajouter une question
          </Button>
          <Button
            autoFocus
            color="inherit"
            onClick={() => setDeletePlaylistDialogOpen(true)}
          >
            Supprimer la playlist
          </Button>
        </Toolbar>
      </AppBar>
      <QuestionsList playlistId={playlistId} themeId={themeId} />
      <QuestionAddDialog
        open={addDialogOpen}
        setOpen={setAddDialogOpen}
        themeId={themeId}
        playlistId={playlistId}
      />
      <PlaylistDeleteDialog
        open={deletePlaylistDialogOpen}
        setOpen={setDeletePlaylistDialogOpen}
        themeId={themeId}
        playlistId={playlistId}
      />
      {/* <DialogActions>
      <Button onClick={handleClose}>Annuler</Button>
      <Button onClick={() => mutation.mutate()}>Confirmer</Button>
    </DialogActions> */}
      {/* </DialogContent> */}
    </Dialog>
  );
};

export default QuestionsDialog;
