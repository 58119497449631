import React from "react";

import "./style.scss";

interface IButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: "primary" | "secondary";
}

const Button = (props: IButtonProps) => {
  const { children, variant = "primary", className, ...rest } = props;

  return (
    <div className={className}>
      <button className={`button ${variant}`} {...rest}>
        {children}
      </button>
    </div>
  );
};

export default Button;
