/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from "react";

import "./style.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { collection } from "firebase/firestore";
import { firestore, storage } from "lib/firebase";
import { useFirestoreCollectionMutation } from "@react-query-firebase/firestore";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

interface IFormInput {
  title: string;
}

const schema = yup.object({
  title: yup.string().required("Le titre est requis"),
});

interface IQuestionAddDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  playlistId: string;
  themeId: string;
}

const QuestionAddDialog = (props: IQuestionAddDialogProps) => {
  const { playlistId, themeId, open, setOpen } = props;

  const ref = collection(
    firestore,
    `Themes/${themeId}/Playlists/${playlistId}/Questions`
  );
  const mutation = useFirestoreCollectionMutation(ref);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      title: "",
    },
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    mutation.mutate({
      title: data.title,
      order: 0,
    });
    reset(), setOpen(false);
  };

  return (
    <Dialog open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Ajouter une question</DialogTitle>
        <DialogContent>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Titre"
                type="title"
                fullWidth
                variant="standard"
                error={!!errors?.title?.message}
                helperText={errors?.title?.message}
                {...field}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button type="submit" disabled={mutation.isLoading}>
            Confirmer
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default QuestionAddDialog;
