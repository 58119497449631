/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from "react";

import "./style.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { collection, doc } from "firebase/firestore";
import { firestore, storage } from "lib/firebase";
import { deleteObject, ref as storef, uploadBytes } from "firebase/storage";
import {
  useFirestoreCollectionMutation,
  useFirestoreDocumentMutation,
} from "@react-query-firebase/firestore";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddCircle } from "@mui/icons-material";

interface IFormInput {
  title: string;
  description: string;
  cover: File;
  icon: File;
  order: number;
}

const schema = yup.object({
  title: yup.string().required("Le titre est requis"),
  description: yup.string().required("La description est requise"),
  // cover: yup.object().required("L'illustration est requise"),
  // icon: yup.object().required("L'icône est requise"),
  order: yup.number().required("L'ordre est requis"),
});

interface IPlaylistEditDialogProps {
  themeId: string;
  playlist: any;
  close: () => void;
}

const PlaylistEditDialog = (props: IPlaylistEditDialogProps) => {
  const { themeId, playlist, close } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const collectionRef = collection(firestore, `Themes/${themeId}/Playlists`);
  const playlistRef = doc(collectionRef, playlist.id);
  const mutation = useFirestoreDocumentMutation(playlistRef, { merge: true });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: playlist.title,
      description: playlist.description,
      cover: null,
      icon: null,
      order: playlist.order,
    },
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    close();
  };

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setLoading(true);
    try {
      if (data.cover) {
        const oldStorageCoverRef = storef(storage, playlist.cover);
        const storageCoverRef = storef(
          storage,
          `/assets/playlists/covers/${data.cover.name}`
        );
        try {
          await deleteObject(oldStorageCoverRef);
        } catch (e) {
          alert(e);
        }
        await uploadBytes(storageCoverRef, data.cover);
      }
      if (data.icon) {
        const oldStorageIconRef = storef(storage, playlist.icon);
        const storageIconRef = storef(
          storage,
          `/assets/playlists/icons/${data.icon.name}`
        );
        try {
          await deleteObject(oldStorageIconRef);
        } catch (e) {
          alert(e);
        }
        await uploadBytes(storageIconRef, data.icon);
      }

      await mutation.mutateAsync({
        title: data.title,
        description: data.description,
        ...(data.cover && {
          cover: `/assets/playlists/covers/${data.cover.name}`,
        }),
        ...(data.icon && { icon: `/assets/playlists/icons/${data.icon.name}` }),
        order: data.order,
      });
      close();
    } catch (e) {
      alert(e);
    } finally {
      setLoading(false);
    }
  };

  if (!playlist || !themeId) {
    return null;
  }

  return (
    <Dialog open={!!playlist}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Modifier une playlist</DialogTitle>
        <DialogContent>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Titre"
                type="title"
                fullWidth
                variant="standard"
                error={!!errors?.title?.message}
                helperText={
                  errors?.title?.message ? String(errors?.title?.message) : null
                }
                {...field}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                autoFocus
                margin="dense"
                id="description"
                label="Description"
                type="description"
                fullWidth
                variant="standard"
                error={!!errors?.description?.message}
                helperText={
                  errors?.description?.message
                    ? String(errors?.description?.message)
                    : null
                }
                {...field}
              />
            )}
          />
          <Controller
            name="cover"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <TextField
                autoFocus
                margin="dense"
                id="cover"
                label="Cover"
                type="file"
                fullWidth
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
                // error={!!errors?.cover?.message}
                // helperText={errors?.cover?.message}
                value={value?.fileName}
                onChange={(e) =>
                  onChange((e.target as HTMLInputElement).files[0])
                }
                {...field}
              />
            )}
          />
          <Controller
            name="icon"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <TextField
                autoFocus
                margin="dense"
                id="icon"
                label="Icône"
                type="file"
                fullWidth
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
                // error={!!errors?.cover?.message}
                // helperText={errors?.cover?.message}
                value={value?.fileName}
                onChange={(e) =>
                  onChange((e.target as HTMLInputElement).files[0])
                }
                {...field}
              />
            )}
          />
          <Controller
            name="order"
            control={control}
            render={({ field }) => (
              <TextField
                autoFocus
                margin="dense"
                id="order"
                label="Ordre"
                type="text"
                fullWidth
                variant="standard"
                error={!!errors?.order?.message}
                helperText={
                  errors?.order?.message ? String(errors?.order?.message) : null
                }
                {...field}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button type="submit" disabled={loading}>
            Confirmer
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PlaylistEditDialog;
