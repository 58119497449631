/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState } from "react";
import "./style.scss";

import models, { IModel } from "datatable/models";
import {
  collection,
  deleteDoc,
  doc,
  query,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "lib/firebase";
import {
  useFirestoreCollectionMutation,
  useFirestoreQuery,
} from "@react-query-firebase/firestore";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridToolbarContainer,
  MuiEvent,
} from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { DragIndicator } from "@mui/icons-material";
import { useDrag } from "react-dnd";
import { DragAndDropButton } from "datatable/components/dragAndDropButton";

interface IDatatableProps {
  name: string;
}

interface IEditToolbarProps {
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
  collectionName: string;
}

function EditToolbar(props: IEditToolbarProps) {
  const { setRowModesModel, collectionName } = props;
  const ref = collection(firestore, collectionName);
  const mutation = useFirestoreCollectionMutation(ref);

  const handleClick = () => {
    mutation.mutate({});
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

const Datatable = (props: IDatatableProps) => {
  const { name } = props;

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const model: IModel = models[name];

  const ref = query(collection(firestore, model.collection));

  const { data, isLoading } = useFirestoreQuery([model.collection], ref, {
    subscribe: true,
  });

  useEffect(() => {
    return () => {
      console.log("TERMINé");
    };
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    // setRows(rows.filter((row) => row.id !== id));
    const toDelRef = doc(firestore, model.collection, id.toString());
    await deleteDoc(toDelRef);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    // if (editedRow!.isNew) {
    //   setRows(rows.filter((row) => row.id !== id));
    // }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const newRowRef = doc(firestore, model.collection, newRow.id);

    const newRowCopy = { ...newRow };
    delete newRowCopy.id;

    await updateDoc(newRowRef, newRowCopy);

    // setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return newRow;
  };

  const rows: GridRowsProp = data.docs.map((item) => ({
    id: item.id,
    ...item?.data(),
  }));

  const columns: GridColDef[] = model.columns.concat();

  columns.push({
    field: "actions",
    type: "actions",
    headerName: "Actions",
    width: 100,
    cellClassName: "actions",
    getActions: ({ id }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            key={0}
            icon={<SaveIcon />}
            label="Save"
            onClick={handleSaveClick(id)}
          />,
          <GridActionsCellItem
            key={1}
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="inherit"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          key={0}
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          key={1}
          icon={<DeleteIcon />}
          label="Delete"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleDeleteClick(id)}
          color="inherit"
        />,
      ];
    },
  });

  columns.unshift({
    field: "order",
    headerName: "Order",
    sortable: false,
    width: 100,
    cellClassName: "actions",
    renderCell: () => <DragAndDropButton />,
  });

  return (
    <div className="datatable">
      <span>{model.title}</span>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: {
            setRowModesModel,
            collectionName: model.collection,
          },
        }}
      />
    </div>
  );
};

export default Datatable;
