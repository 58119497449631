import React from "react";
import ViewRelationalData from "datatable/components/viewRelationalData";
import { IModel } from ".";
import EditSelectRelationalData from "datatable/components/editSelectRelationalData";

export interface IPlaylist {
  title: string;
  themeId: string;
}

const playlists: IModel = {
  title: "Playlists",
  name: "Playlists",
  collection: "Playlists",
  columns: [
    {
      headerName: "Title",
      field: "title",
      width: 250,
      editable: true,
    },
    {
      headerName: "Theme",
      field: "themeId",
      width: 250,
      renderCell: ({ value }: { value?: string }) => (
        <ViewRelationalData
          value={value}
          relationalCollection="Themes"
          relationalKey="title"
        />
      ),
      renderEditCell: (params) => (
        <EditSelectRelationalData
          relationalCollection="Themes"
          relationalDocLabelKey="title"
          gridRenderCellParams={params}
        />
      ),
      editable: true,
    },
  ],
};

export default playlists;
