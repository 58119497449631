/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";

import "./style.scss";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Input,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DocumentReference, collection, doc } from "firebase/firestore";
import {
  useFirestoreDocumentDeletion,
  useFirestoreDocumentMutation,
} from "@react-query-firebase/firestore";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import PlaylistsList from "components/playlists/list";
import { firestore } from "lib/firebase";

interface IQuestionItemProps {
  data: any;
  themeId: string;
  playlistId: string;
}

const QuestionItem = (props: IQuestionItemProps) => {
  const { data, playlistId, themeId } = props;

  const [open, setOpen] = useState<boolean>(false);

  const questionCollection = collection(
    firestore,
    `Themes/${themeId}/Playlists/${playlistId}/Questions`
  );
  const questionRef = doc(questionCollection, data.id);
  const mutation = useFirestoreDocumentDeletion(questionRef);
  const editMutation = useFirestoreDocumentMutation(questionRef, {
    merge: true,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleIncrementOrder = () => {
    editMutation.mutate({
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      order: data.order + 1,
    });
  };

  const handleDecrementOrder = () => {
    editMutation.mutate({
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      order: data.order - 1,
    });
  };

  const handleChangeOrder = (e: any) => {
    editMutation.mutate({
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      order: e.target.value,
    });
  };

  const handleRenameQuestion = (e: any) => {
    editMutation.mutate({
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      title: e.target.value,
    });
  };

  return (
    <Card className="theme-item-container">
      <div className="theme-item-header-container">
        <TextField
          size="small"
          variant="outlined"
          className="theme-item-title"
          value={data.title}
          onChange={handleRenameQuestion}
        />
        <div>
          <IconButton onClick={handleIncrementOrder}>
            <ArrowDownward />
          </IconButton>
          <TextField
            size="small"
            value={data?.order}
            onChange={handleChangeOrder}
          />
          <IconButton onClick={handleDecrementOrder}>
            <ArrowUpward />
          </IconButton>
          <IconButton onClick={handleClickOpen}>
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Attention</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Étes-vous sûr de vouloir supprimer la question {data?.title} ?
          </DialogContentText>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button onClick={() => mutation.mutate()}>Confirmer</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default QuestionItem;
