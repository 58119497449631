/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { collection, query } from "firebase/firestore";
import { firestore } from "lib/firebase";
import { MenuItem, Select } from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid";

interface IEditRelationDataProps {
  relationalCollection: string;
  relationalDocLabelKey: string;
  gridRenderCellParams: GridRenderCellParams;
}

const EditSelectRelationalData = (props: IEditRelationDataProps) => {
  const { relationalCollection, relationalDocLabelKey, gridRenderCellParams } =
    props;
  const apiRef = useGridApiContext();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { id, value, field } = gridRenderCellParams;

  const ref = query(collection(firestore, relationalCollection));
  const { data, isLoading } = useFirestoreQuery(relationalCollection, ref);

  const handleChange = async (event: SelectChangeEvent) => {
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: event.target.value,
    });
    apiRef.current.stopCellEditMode({
      id,
      field,
    });
  };

  if (isLoading) {
    return null;
  }

  const items = data.docs.map((doc) => ({
    value: doc.id,
    label: doc.data()[relationalDocLabelKey],
  }));

  return (
    <Select
      value={value}
      onChange={handleChange}
      size="small"
      sx={{ height: 1 }}
      autoFocus
    >
      {items.map((item) => (
        <MenuItem value={item.value} key={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default EditSelectRelationalData;
