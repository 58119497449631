import React from "react";
import { IModel } from ".";

const tagsCategories: IModel = {
  title: "TagsCategories",
  name: "TagsCategories",
  collection: "TagsCategory",
  columns: [
    {
      headerName: "Title",
      field: "title",
      width: 250,
      editable: true,
    },
  ],
};

export default tagsCategories;
