/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import "./style.css";
import { useAuth } from "provider/AuthProvider";
import TextField from "uilib/textField";
import Button from "uilib/button";

interface IFormValues {
  email: string;
  password: string;
}

const schema = yup
  .object({
    email: yup
      .string()
      .email("Veuillez entrer un mail valide")
      .required("Veuillez entrer votre mail"),
    password: yup.string().required("Veullez entrer votre mot de passe"),
  })
  .required();

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
  });

  const { signIn } = useAuth();

  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);

  const onSignIn = async (email: string, password: string) => {
    setFormSubmitting(true);
    try {
      await signIn(email, password);
    } catch (error) {
      console.log(error);
    } finally {
      setFormSubmitting(false);
    }
    reset();
  };

  const onSubmit = (data: IFormValues) => {
    onSignIn(data.email, data.password);
  };

  return (
    <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label="Email"
        type="email"
        name="email"
        placeholder="email@example.com"
        {...register("email")}
        error={typeof errors.email === "undefined" ? false : true}
        helperText={errors.email?.message}
      />
      <TextField
        label="Mot de passe"
        type="password"
        name="password"
        placeholder="Mot de passe"
        {...register("password")}
        error={typeof errors.password === "undefined" ? false : true}
        helperText={errors.password?.message}
      />
      <Button type="submit" variant="primary">
        Se connecter
      </Button>
    </form>
  );
};

export default LoginForm;
