import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: ["Raleway"].join(","),
  },
  palette: {
    primary: {
      main: "#EF8833",
      contrastText: "white",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "90px",
        },
      },
    },
  },
});

export default theme;
