import React from "react";
import LoginForm from "./components/form";

import "./style.scss";

const Login = () => {
  return (
    <div className="login-container">
      <LoginForm />
    </div>
  );
};

export default Login;
