import EditSelectRelationalData from "datatable/components/editSelectRelationalData";
import ViewRelationalData from "datatable/components/viewRelationalData";
import { IModel } from "datatable/models";
import React from "react";

export interface ITag {
  title: string;
  categoryId: string;
}

const tags: IModel = {
  title: "Tags",
  name: "Tags",
  collection: "Tags",
  columns: [
    {
      headerName: "Title",
      field: "title",
      width: 250,
      editable: true,
    },
    {
      headerName: "Category",
      field: "categoryId",
      renderCell: ({ value }: { value?: string }) => (
        <ViewRelationalData
          value={value}
          relationalCollection="TagsCategory"
          relationalKey="title"
        />
      ),
      width: 250,
      renderEditCell: (params) => (
        <EditSelectRelationalData
          relationalCollection="TagsCategory"
          relationalDocLabelKey="title"
          gridRenderCellParams={params}
        />
      ),
      editable: true,
    },
  ],
};

export default tags;
