/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { IFormValues } from "components/users/create/form";
import { FunctionsError, httpsCallable } from "firebase/functions";
import { functions } from "lib/firebase";
import React from "react";
import { useMutation } from "react-query";

const useCreateUserMutation = () => {
  return useMutation({
    mutationKey: ["createUser"],
    mutationFn: async (data: IFormValues) => {
      const createUser = httpsCallable<IFormValues, FunctionsError>(
        functions,
        "createUser"
      );

      const res = await createUser(data);

      return res;
    },
    onError: (e: any) => {
      console.log(e);
    },
  });
};

export default useCreateUserMutation;
