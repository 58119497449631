import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import React from "react";

import "./style.scss";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="home">
      <Link to="/themes">
        <Button variant="contained" className="home-basic-button">
          Gérer les thèmes
        </Button>
      </Link>
      <Link to="/users/create">
        <Button variant="contained" className="home-basic-button">
          Créer un utilisateur
        </Button>
      </Link>
      <Button className="home-basic-button">Se déconnecter</Button>
    </div>
  );
};

export default Home;
