import { GridColDef } from "@mui/x-data-grid";
import questions from "./questions";
import playlists from "./playlists";
import themes from "./themes";
import tags from "./tags";
import tagsCategories from "./tagsCategories";
import users from "./users";

export interface IModel {
  title: string;
  name: string;
  collection: string;
  columns: GridColDef[];
}

interface IModels {
  [name: string]: IModel;
}

const models: IModels = {
  tags: tags,
  questions: questions,
  playlists: playlists,
  themes: themes,
  users: users,
  tagsCategories: tagsCategories,
};

export default models;
