import { Timestamp } from "firebase/firestore";

export interface IUser {
  email: string;
  firstname: string;
  name: string;
  phone: string;
  createdAt: Date;
}

const users = {
  title: "Users",
  name: "Users",
  collection: "Users",
  columns: [
    {
      headerName: "E-Mail",
      field: "email",
      width: 250,
    },
    {
      headerName: "Firstname",
      field: "firstname",
      width: 250,
    },
    {
      headerName: "Name",
      field: "name",
      width: 250,
    },
    {
      headerName: "Phone",
      field: "phone",
      width: 250,
    },
    {
      headerName: "Creation Date",
      field: "createdAt",
      type: "dateTime",
      valueGetter: ({ value }: { value: Timestamp }) => value.toDate(),
      width: 250,
    },
  ],
  type: {} as IUser,
};

export default users;
