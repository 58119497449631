/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from "react";

import "./style.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { collection } from "firebase/firestore";
import { firestore, storage } from "lib/firebase";
import { ref as storef, uploadBytes } from "firebase/storage";
import { useFirestoreCollectionMutation } from "@react-query-firebase/firestore";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddCircle } from "@mui/icons-material";

interface IFormInput {
  title: string;
  description: string;
  cover: File;
  icon: File;
}

const schema = yup.object({
  title: yup.string().required("Le titre est requis"),
  description: yup.string().required("La description est requise"),
  cover: yup.mixed().required("L'illustration est requise"),
  icon: yup.mixed().required("L'icône est requise"),
});

interface IPlaylistAddProps {
  themeId: string;
}

const PlaylistAdd = (props: IPlaylistAddProps) => {
  const { themeId } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const ref = collection(firestore, `Themes/${themeId}/Playlists`);
  const mutation = useFirestoreCollectionMutation(ref);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      cover: null,
      icon: null,
    },
    resolver: yupResolver(schema),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const storageCoverRef = storef(
      storage,
      `/assets/playlists/covers/${data.cover.name}`
    );
    const storageIconRef = storef(
      storage,
      `/assets/playlists/icons/${data.icon.name}`
    );

    setLoading(true);
    try {
      await uploadBytes(storageCoverRef, data.cover);
      await uploadBytes(storageIconRef, data.icon);

      await mutation.mutateAsync({
        title: data.title,
        description: data.description,
        cover: `/assets/playlists/covers/${data.cover.name}`,
        icon: `/assets/playlists/icons/${data.icon.name}`,
        order: 0,
      });
      reset(), setOpen(false);
    } catch (e) {
      alert(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="playlist-add-container">
      {/* <Button
        variant="contained"
        color="primary"
        className="theme-add-button"
        onClick={handleClickOpen}
      >
        Ajouter un thème
      </Button> */}
      <div className="playlist-add-button" onClick={handleClickOpen}>
        <AddCircle className="playlist-add-icon" />
        Nouvelle playlist
      </div>

      <Dialog open={open}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Ajouter une playlist</DialogTitle>
          <DialogContent>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Titre"
                  type="title"
                  fullWidth
                  variant="standard"
                  error={!!errors?.title?.message}
                  helperText={errors?.title?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  autoFocus
                  margin="dense"
                  id="description"
                  label="Description"
                  type="description"
                  fullWidth
                  variant="standard"
                  error={!!errors?.description?.message}
                  helperText={errors?.description?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="cover"
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <TextField
                  autoFocus
                  margin="dense"
                  id="cover"
                  label="Cover"
                  type="file"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errors?.cover?.message}
                  // helperText={errors?.cover?.message}
                  value={value?.fileName}
                  onChange={(e) =>
                    onChange((e.target as HTMLInputElement).files[0])
                  }
                  {...field}
                />
              )}
            />
            <Controller
              name="icon"
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <TextField
                  autoFocus
                  margin="dense"
                  id="icon"
                  label="Icône"
                  type="file"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errors?.cover?.message}
                  // helperText={errors?.cover?.message}
                  value={value?.fileName}
                  onChange={(e) =>
                    onChange((e.target as HTMLInputElement).files[0])
                  }
                  {...field}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button type="submit" disabled={loading}>
              Confirmer
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default PlaylistAdd;
