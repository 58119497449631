import React from "react";
import "./style.scss";
import Sidebar from "components/sidebar";
import Navbar from "components/navbar";
import Datatable from "components/datatable";
// import Datatable from "components/datatable";

interface IListProps {
  name: string;
}

const List = (props: IListProps) => {
  const { name } = props;

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <Datatable name={name} />
      </div>
    </div>
  );
};

export default List;
