import { DragIndicator } from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import React from "react";
import { useDrag } from "react-dnd";

export const DragAndDropButton = () => {
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: "card",
      item: "lol",
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
    []
  );

  return (
    <GridActionsCellItem
      key={0}
      icon={<DragIndicator />}
      label="Edit"
      className="textPrimary"
      // onClick={handleEditClick(id)}
      color="inherit"
      ref={dragRef}
    />
  );
};
