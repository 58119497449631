import React from "react";
import ViewRelationalData from "datatable/components/viewRelationalData";
import { IModel } from ".";
import EditSelectRelationalData from "datatable/components/editSelectRelationalData";

export interface IQuestion {
  title: string;
  playlistId: string;
}

const questions: IModel = {
  title: "QuestionsV2",
  name: "QuestionsV2",
  collection: "QuestionsV2",
  columns: [
    {
      headerName: "Title",
      field: "title",
      width: 250,
      editable: true,
    },
    {
      headerName: "Playlist",
      field: "playlistId",
      width: 250,
      renderCell: ({ value }: { value?: string }) => (
        <ViewRelationalData
          value={value}
          relationalCollection="Playlists"
          relationalKey="title"
        />
      ),
      renderEditCell: (params) => (
        <EditSelectRelationalData
          relationalCollection="Playlists"
          relationalDocLabelKey="title"
          gridRenderCellParams={params}
        />
      ),
      editable: true,
    },
  ],
};

export default questions;
