import Login from "pages/login";
import React from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "provider/AuthProvider";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";

export const ProtectedRoute = () => {
  const { user } = useAuth();

  return typeof user === "undefined" ? (
    <h1>Loading.....</h1>
  ) : user ? (
    <>
      <Navbar />
      <Outlet />
    </>
  ) : (
    <Login />
  );
};
