import UsersCreateForm from "components/users/create/form";
import React from "react";

import "./style.scss";

const UsersCreatePage = () => {
  return (
    <div className="users-create-page-container">
      <h1>Créer un utilisateur</h1>
      <UsersCreateForm />
    </div>
  );
};

export default UsersCreatePage;
