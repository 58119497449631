/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { KeyboardEventHandler, useEffect, useState } from "react";

import "./style.scss";
import {
  AppBar,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Input,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { collection, doc } from "firebase/firestore";
import { firestore, storage } from "lib/firebase";
import {
  useFirestoreDocumentDeletion,
  useFirestoreDocumentMutation,
} from "@react-query-firebase/firestore";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { getDownloadURL, ref } from "firebase/storage";

interface IPlaylistItemProps extends React.HTMLAttributes<HTMLDivElement> {
  data: any;
}

const PlaylistItem = (props: IPlaylistItemProps) => {
  const { data, ...rest } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [coverUrl, setCoverUrl] = useState<string>(null);
  const [iconUrl, setIconUrl] = useState<string>(null);

  const playlistsCollection = collection(firestore, "Playlists");
  const playlistRef = doc(playlistsCollection, data.id);
  const mutation = useFirestoreDocumentDeletion(playlistRef);
  const editMutation = useFirestoreDocumentMutation(playlistRef, {
    merge: true,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleIncrementOrder = () => {
    editMutation.mutate({
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      order: data.order + 1,
    });
  };

  const handleDecrementOrder = () => {
    editMutation.mutate({
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      order: data.order - 1,
    });
  };

  const handleChangeOrder = (e: any) => {
    editMutation.mutate({
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      order: e.target.value,
    });
  };

  const handleRenameTheme = (e: any) => {
    editMutation.mutate({
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      title: e.target.value,
    });
  };

  useEffect(() => {
    const fetchPictures = async () => {
      const coverRef = ref(storage, data?.cover);
      const iconRef = ref(storage, data?.icon);

      try {
        const fetchedCoverUrl = await getDownloadURL(coverRef);
        const fetchedIconUrl = await getDownloadURL(iconRef);

        setCoverUrl(fetchedCoverUrl);
        setIconUrl(fetchedIconUrl);
      } catch (e) {
        alert(e);
      }
    };
    void fetchPictures();
  }, [data]);

  return (
    <div
      className="playlist-item-container"
      onClick={handleClickOpen}
      {...rest}
    >
      {coverUrl ? <img src={coverUrl} className="playlist-item-cover" /> : null}
      <div className="playlist-item-details-container">
        {iconUrl ? <img src={iconUrl} className="playlist-item-icon" /> : null}
        <span className="playlist-item-title">{data?.title}</span>
      </div>
      {/* <TextField
        size="small"
        variant="outlined"
        className="theme-item-title"
        value={data.title}
        onChange={handleRenameTheme}
      />
      <div className="theme-item-actions-container">
        <IconButton onClick={handleIncrementOrder}>
          <ArrowDownward />
        </IconButton>
        <TextField
          size="small"
          value={data?.order}
          onChange={handleChangeOrder}
        />
        <IconButton onClick={handleDecrementOrder}>
          <ArrowUpward />
        </IconButton>
        <IconButton onClick={handleClickOpen}>
          <DeleteIcon />
        </IconButton>
      </div> */}
      {/* <Dialog open={open} onClose={handleClose} fullScreen>
        <DialogContent>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {open ? "true" : "false"}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button onClick={() => mutation.mutate()}>Confirmer</Button>
          </DialogActions>
        </DialogContent>
      </Dialog> */}
    </div>
  );
};

export default PlaylistItem;
