/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";

import "./style.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { collection, doc } from "firebase/firestore";
import { firestore } from "lib/firebase";
import { useFirestoreDocumentDeletion } from "@react-query-firebase/firestore";

interface IPlaylistDeleteDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  playlistId: string;
  themeId: string;
}

const PlaylistDeleteDialog = (props: IPlaylistDeleteDialogProps) => {
  const { playlistId, themeId, open, setOpen } = props;

  const playlistCollection = collection(
    firestore,
    `Themes/${themeId}/Playlists`
  );
  const playlistRef = doc(playlistCollection, playlistId);
  const deleteMutation = useFirestoreDocumentDeletion(playlistRef);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeletePlaylist = () => {
    deleteMutation.mutate();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Attention</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Étes-vous sûr de vouloir supprimer la playlist ?
        </DialogContentText>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleDeletePlaylist}>Confirmer</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default PlaylistDeleteDialog;
