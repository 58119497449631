import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AppRouter from "routes/index";
import { AuthProvider } from "provider/AuthProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ThemeProvider } from "@emotion/react";
import theme from "theme";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <DndProvider backend={HTML5Backend}>
          <ThemeProvider theme={theme}>
            <AppRouter />
          </ThemeProvider>
        </DndProvider>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
