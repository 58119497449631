/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import useCreateUserMutation from "hooks/users/useCreateUserMutation";
import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

export interface IFormValues {
  email: string;
  password: string;
  firstName: string;
  name: string;
  subscription: boolean;
}

const schema = yup.object({
  email: yup
    .string()
    .email("Un email valide est requis")
    .required("L'email est requis"),
  password: yup
    .string()
    .min(8, "Le mot de passe doit contenir au moins 8 caractères")
    .max(32, "Le mot de passe doit contenir au plus 32 caractères")
    .required("Le mot de passe est requis"),
  firstName: yup.string(),
  name: yup.string(),
  subscription: yup.boolean(),
});

const UsersCreateForm = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<IFormValues>({
    defaultValues: {
      email: "",
      password: "",
      firstName: "",
      name: "",
      subscription: true,
    },
    resolver: yupResolver(schema),
  });

  const mutation = useCreateUserMutation();

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    mutation.mutate(data, { onSuccess: () => reset() });
  };

  const generatePassword = (
    length = 8,
    characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$"
  ) => {
    return Array.from(crypto.getRandomValues(new Uint32Array(length)))
      .map((x) => characters[x % characters.length])
      .join("");
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: "flex", flexDirection: "column", gap: "20px" }}
    >
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            required
            error={!!errors?.email?.message}
            helperText={errors?.email?.message}
            {...field}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Mot de passe"
            type="text"
            fullWidth
            variant="standard"
            error={!!errors?.password?.message}
            helperText={errors?.password?.message}
            required
            {...field}
          />
        )}
      />
      <Button
        variant="outlined"
        onClick={() => setValue("password", generatePassword())}
      >
        Générer un mot de passe
      </Button>
      <Controller
        name="firstName"
        control={control}
        render={({ field }) => (
          <TextField
            autoFocus
            margin="dense"
            id="firstName"
            label="Prénom (Optionnel)"
            type="text"
            fullWidth
            variant="standard"
            error={!!errors?.firstName?.message}
            helperText={errors?.firstName?.message}
            {...field}
          />
        )}
      />
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nom (Optionnel)"
            type="text"
            fullWidth
            variant="standard"
            error={!!errors?.name?.message}
            helperText={errors?.name?.message}
            {...field}
          />
        )}
      />
      <Controller
        name="subscription"
        control={control}
        render={({ field: { value, onBlur, onChange, ref } }) => (
          <FormControlLabel
            label={"Activer un abonnement SOLO"}
            control={
              <Checkbox
                checked={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
              />
            }
          />
        )}
      />
      <Button
        type="submit"
        variant="contained"
        disabled={mutation.isLoading}
        fullWidth
      >
        Valider
      </Button>
      {mutation.isError ? (
        <p style={{ color: "red", textAlign: "center" }}>
          {mutation.error ? mutation.error?.message : null}
        </p>
      ) : null}
      {mutation.isSuccess ? (
        <p style={{ color: "green", textAlign: "center" }}>
          Le compte a bien été créé
        </p>
      ) : null}
    </form>
  );
};

export default UsersCreateForm;
