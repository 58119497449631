import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { collection, orderBy, query } from "firebase/firestore";
import { firestore } from "lib/firebase";
import React, { useState } from "react";

import "./style.scss";
import PlaylistItem from "../item";
import PlaylistAdd from "../add";
import { Button, Grid } from "@mui/material";
import QuestionsDialog from "components/questions/dialog";
import PlaylistEditDialog from "../edit";

interface IPlaylistListProps {
  themeId: string;
}

const PlaylistsList = (props: IPlaylistListProps) => {
  const { themeId } = props;
  const [playlistId, setPlaylistId] = useState<string>(null);
  const [editPlaylist, setEditPlaylist] = useState<unknown>(null);

  const handleClickOpen = (selectedPlaylistId: string) => {
    setPlaylistId(selectedPlaylistId);
  };

  const handleClickOpenEdit = (selectedPlaylistEdit: unknown) => {
    setEditPlaylist(selectedPlaylistEdit);
  };

  const handleCloseEdit = () => {
    setEditPlaylist(null);
  };

  const handleClose = () => {
    setPlaylistId(null);
  };

  const ref = query(
    collection(firestore, `Themes/${themeId}/Playlists`),
    orderBy("order")
  );

  const themesQuery = useFirestoreQuery([`Themes/${themeId}/Playlists`], ref, {
    subscribe: true,
  });

  if (themesQuery.isLoading) {
    return <div>Loading...</div>;
  }

  const snapshot = themesQuery.data;

  return (
    <>
      <Grid container columns={16} gap={2} className="playlist-list-container">
        {snapshot.docs.map((docSnapshot) => {
          const data = docSnapshot.data();

          return (
            <Grid key={docSnapshot.id} alignItems="center">
              <PlaylistItem
                data={{ id: docSnapshot.id, ...data }}
                onClick={() => handleClickOpen(docSnapshot.id)}
              />
              <Button
                onClick={() =>
                  handleClickOpenEdit({ id: docSnapshot.id, ...data })
                }
              >
                Modifier
              </Button>
            </Grid>
          );
        })}
        <Grid>
          <PlaylistAdd themeId={themeId} />
        </Grid>
      </Grid>
      <QuestionsDialog
        playlistId={playlistId}
        themeId={themeId}
        close={handleClose}
      />
      {themeId && editPlaylist ? (
        <PlaylistEditDialog
          playlist={editPlaylist}
          themeId={themeId}
          close={handleCloseEdit}
        />
      ) : null}
    </>
  );
};

export default PlaylistsList;
