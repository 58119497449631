import React, { forwardRef } from "react";

import "./style.css";

interface ITextFieldProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  error?: boolean;
  helperText?: string;
}

const TextField = forwardRef<HTMLInputElement, ITextFieldProps>(
  (props, ref) => {
    const { label, error, helperText, ...rest } = props;

    return (
      <div className="input-container">
        <span className="input-label">{label}</span>
        <input ref={ref} {...rest} />
        {!!error && <span className="input-error">{helperText}</span>}
      </div>
    );
  }
);

export default TextField;
