import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { collection, orderBy, query } from "firebase/firestore";
import { firestore } from "lib/firebase";
import React from "react";

import "./style.scss";
import QuestionItem from "../item";

interface IQuestionsListProps {
  themeId: string;
  playlistId: string;
}

const QuestionsList = (props: IQuestionsListProps) => {
  const { themeId, playlistId } = props;

  const ref = query(
    collection(
      firestore,
      `Themes/${themeId}/Playlists/${playlistId}/Questions`
    ),
    orderBy("order")
  );

  console.log(`Themes/${themeId}/Playlists/${playlistId}/Questions`);

  const questionsQuery = useFirestoreQuery(
    [`Themes/${themeId}/Playlists/${playlistId}/Questions`],
    ref,
    { subscribe: true }
  );

  if (questionsQuery.isLoading) {
    return <div>Loading...</div>;
  }

  const snapshot = questionsQuery.data;

  return (
    <div className="questions-list-container">
      {snapshot.docs.map((docSnapshot) => {
        const data = docSnapshot.data();

        return (
          <QuestionItem
            key={docSnapshot.id}
            data={{ id: docSnapshot.id, ...data }}
            themeId={themeId}
            playlistId={playlistId}
          />
        );
      })}
    </div>
  );
};

export default QuestionsList;
