import React from "react";
import { IModel } from ".";

const themes: IModel = {
  title: "Themes",
  name: "Themes",
  collection: "Themes",
  columns: [
    {
      headerName: "Title",
      field: "title",
      width: 250,
      editable: true,
    },
  ],
};

export default themes;
