import Home from "pages/home";
import List from "pages/list";
import Login from "pages/login";
import Tags from "pages/tags";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./protectedRoute";
import ThemesPage from "pages/themes";
import UsersCreatePage from "pages/users/create";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/tags">
            <Route index element={<List name="tags" key={0} />} />
          </Route>
          <Route path="/tagsCategories">
            <Route index element={<List name="tagsCategories" key={1} />} />
          </Route>
          <Route path="/questions">
            <Route index element={<List name="questions" key={2} />} />
          </Route>
          <Route path="/themes">
            <Route index element={<ThemesPage />} />
          </Route>
          <Route path="/playlists">
            <Route index element={<List name="playlists" key={4} />} />
          </Route>
          <Route path="/users">
            <Route index element={<List name="users" key={4} />} />
          </Route>
          <Route path="/users/create">
            <Route index element={<UsersCreatePage />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
