import ThemeAdd from "components/themes/add";
import ThemesList from "components/themes/list";
import React from "react";

const ThemesPage = () => {
  return (
    <div>
      <ThemeAdd />
      <ThemesList />
    </div>
  );
};

export default ThemesPage;
