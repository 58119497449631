/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from "react";

import "./style.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { collection } from "firebase/firestore";
import { firestore } from "lib/firebase";
import { useFirestoreCollectionMutation } from "@react-query-firebase/firestore";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

interface IFormInput {
  title: string;
}

const schema = yup.object({
  title: yup.string().required("Le titre est requis"),
});

const ThemeAdd = () => {
  const [open, setOpen] = useState<boolean>(false);
  const ref = collection(firestore, "Themes");
  const mutation = useFirestoreCollectionMutation(ref);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      title: "",
    },
    resolver: yupResolver(schema),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    mutation.mutate({
      title: data.title,
    });
    reset(), setOpen(false);
  };

  return (
    <div className="theme-add-container">
      <Button
        variant="contained"
        color="primary"
        className="theme-add-button"
        onClick={handleClickOpen}
      >
        Ajouter un thème
      </Button>

      <Dialog open={open}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Ajouter un thème</DialogTitle>
          <DialogContent>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Titre"
                  type="title"
                  fullWidth
                  variant="standard"
                  error={!!errors?.title?.message}
                  helperText={errors?.title?.message}
                  {...field}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button type="submit">Confirmer</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default ThemeAdd;
